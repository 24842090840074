<template>
  <div style="display: inline-block;">
    <div
      :class="className"
      id="logo"
      :style="{ color: logoColor, ...dynamicStyle }"
    >
      <!-- <v-icon class="spot-icon" :color="logoColor">mdi-map-marker</v-icon> -->
      <v-img
        class="spot-icon mb-1"
        max-width="50"
        src="../assets/spot-black.svg"
        v-if="isBlack"
      ></v-img>
      <v-img
        class="spot-icon mb-1"
        max-width="50"
        src="../assets/spot.svg"
        v-else
      ></v-img>
      <span>Review</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: ["color", "dynamicStyle", "router", "className", "isBlack"],
  data() {
    return {
      logoColor: null,
    };
  },

  mounted() {
    this.logoColor = this.color || (this.isBlack ? "#212121" : "#eceff1");
  },
};
</script>

<style lang="scss" scoped>
#logo {
  display: inline-block;
  font-size: 0.9rem;

  span {
    font-weight: bold;
    margin-left: -7px;
    text-transform: capitalize;
    letter-spacing: 3px;
  }
}
</style>
