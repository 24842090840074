<template>
  <footer class="footer">
    <div class="footer__two">
      <div class="container">
        <div>
          &copy; {{ new Date().getFullYear() }} — Developed with ❤️ by
          <a :href="account" target="_blank">Mohamed Mahmoud</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      account: "http://devmhmd.com",
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  a {
    text-decoration: none;
    color: white;
    text-transform: capitalize;
    // letter-spacing: 1px;
    margin-left: 3px;
    font-weight: bold;
    font-size: 0.9rem;
  }
}

.footer {
  font-size: 0.9rem;
  position: relative;
  background-image: linear-gradient(
    to right,
    #09305d,
    #0a3d73,
    #0a4a8b,
    #0957a2,
    #0565bb
  );
  color: #fff;
}

.footer__one {
  overflow: hidden;
}

.footer__one-container {
  padding: 50px 20px 40px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: left;
  -ms-flex-pack: left;
  justify-content: left;
}

@media (max-width: 991.98px) {
  .footer__one-container {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer__menus {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: justify;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .footer__menus {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
}

.footer__menu {
  margin: 0;
  padding: 0 4rem 0 0;
}

@media (max-width: 991.98px) {
  .footer__menu {
    width: 20%;
    padding: 0 10px;
    margin-bottom: 30px;
    min-width: 230px;
  }
}

.footer__menu-item {
  font-weight: 300;
  list-style: none;
  margin-bottom: 0.6rem;
}

.footer__menu-item > a {
  text-decoration: none;
  color: #fff;
}

.footer__menu-item > a:hover {
  text-decoration: underline;
}

.footer__menu-item--title {
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 10px;
}

.footer__one-right {
  max-width: 200px;
  margin-left: auto;
}

@media (max-width: 991.98px) {
  .footer__one-right {
    clear: both;
    text-align: center;
    margin-left: 0;
    max-width: none;
  }
}

@media (max-width: 767.98px) {
  .footer__one-right {
    min-width: inherit;
  }
}

.footer__two {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
  overflow: hidden;
  padding: 1.2rem 0;
  font-size: 0.8rem;
}

.footer__two > .container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: justify;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .footer__two > .container {
    text-align: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer__copy {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 991.98px) {
  .footer__copy {
    margin-bottom: 1rem;
  }
}

.footer__copy > p {
  margin-bottom: 0.5rem;
}

.footer__wireguard {
  opacity: 0.5;
}
</style>
