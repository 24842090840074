<template>
  <v-app>
    <template v-if="currentRouteName !== 'Homepage'">
      <AppHeader :headerTop="true" :currentUser="currentUser" />

      <v-content class="blue-grey lighten-5 py-8">
        <router-view :currentUser="currentUser" />
      </v-content>

      <AppFooter />
    </template>

    <router-view :currentUser="currentUser" v-else />
  </v-app>
</template>

<script>
import { auth } from "@/firebase/firebaseConfig";

import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";

export default {
  data() {
    return {
      currentUser: undefined,
    };
  },
  components: { AppHeader, AppFooter },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  mounted() {
    this.$router.beforeEach((to, from, next) => {
      if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !this.currentUser
      ) {
        next({ path: "/" });
      } else next();
    });

    auth.onAuthStateChanged((user) => {
      this.currentUser = user;
      const routes = this.$router.options.routes;
      const currentRoute = routes.filter(
        (item) => item.name === this.currentRouteName
      )[0];

      if (user && currentRoute?.name === "Homepage")
        this.$router.push({ name: "UserHomePage" });
      if (currentRoute?.meta?.requiresAuth && !user) {
        this.$router.push({ name: "Homepage" });
      }
    });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: 0;
  border: 0;
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.absolute-center {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
