<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" @click="closeMobMenuBox" v-if="!hideBtn">
        <slot>
          <v-btn
            text
            class="btn btn-lg btn--yellow btn--yellow-border header__btn-join"
          >
            <!-- <span class="mr-2">login</span>
            <v-icon>mdi-login</v-icon> -->
            get started
          </v-btn>
        </slot>
      </span>
    </template>

    <v-card>
      <v-card-text style="text-align:center">
        <Logo
          :isBlack="true"
          color="#454545"
          :dynamicStyle="{
            margin: '25px auto 30px',
          }"
        />
        <p style="text-align: left; width: 90%; margin: 0 auto 17px;">
          You are <b>anonymos</b> user, You won't be able to see your reviews
          again. Please <b>signup</b> to make reviews attached to you. Thanks,
          Hope you enjoy ❤️.
        </p>
        <GoogleLoginButton />
      </v-card-text>

      <!-- <v-divider></v-divider> -->

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GoogleLoginButton from "./GoogleLoginButton";
import Logo from "./Logo";

export default {
  name: "LoginPopup",
  components: { GoogleLoginButton, Logo },
  props: ["defaultOpen", "hideBtn", "closeMobMenuBox"],
  data() {
    return {
      dialog: false,
    };
  },

  mounted() {
    if (this.defaultOpen) this.dialog = true;
  },
};
</script>

<style lang="scss" scoped>
.btn.btn--yellow-border {
  color: #ffbe35;

  &:hover,
  &:focus,
  &:active {
    color: white;
  }

  span > * {
    transition: all 0ms !important;
  }
}
</style>
