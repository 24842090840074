import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Homepage',
    component: () => import('../views/HomePage.vue')
  },

  {
    path: '/image/:id/:docid',
    name: 'ImageStage2',
    component: () => import('../views/ImageStage.vue')
  },

  {
    path: '/user/',
    name: 'UserHomePage',
    component: () => import('../views/UserHomePage.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
