import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyCYq4IlSXFfw3v4fki7BQ4Sy6jHddHAiME',
  authDomain: 'spot-review.firebaseapp.com',
  projectId: 'spot-review',
  storageBucket: 'spot-review.appspot.com',
  messagingSenderId: '688595879520',
  appId: '1:688595879520:web:91f9906015aa904c646505',
  measurementId: 'G-5M9RGFT6LC'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

// firebase services
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()


export {
  db,
  auth,
  storage
}