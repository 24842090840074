<template>
  <header
    class="header header--landing"
    :class="{ 'header--top': headerTop }"
    :style="{ position: headerTop ? 'relative' : null }"
  >
    <div class="container" style="padding: 12px">
      <router-link :to="{ name: 'Homepage' }">
        <Logo className="header__logo" />
      </router-link>
      <button
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="menu"
        class="header__btn-toggle"
        data-target="#navbar"
      >
        <span class="header__btn-toggle-line header__btn-toggle-line--1"></span>
        <span class="header__btn-toggle-line header__btn-toggle-line--2"></span>
        <span class="header__btn-toggle-line header__btn-toggle-line--3"></span>
      </button>
      <ul class="header__nav" id="navbar">
        <template v-if="currentUser">
          <li class="header__nav-item" @click="closeMobMenuBox">
            <router-link :to="{ name: 'UserHomePage' }">
              <v-avatar size="40" class="mr-2">
                <img
                  referrerpolicy="no-referrer"
                  :src="currentUser.photoURL"
                  :alt="currentUser.displayName"
                />
              </v-avatar>
            </router-link>
          </li>
          <li class="header__nav-item" @click="closeMobMenuBox">
            <v-btn dark text @click="logout">
              <span class="mr-2">logout</span>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </li>
        </template>

        <li class="header__nav-item" @click="closeMobMenuBox" v-else>
          <LoginPopup :closeMobMenuBox="closeMobMenuBox" />
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { googleSignIn, firebaseLogout } from "@/firebase/auth";
import Logo from "./Logo";
import LoginPopup from "./LoginPopup";
import main from "../assets/js/main";

export default {
  name: "AppHeader",
  components: { Logo, LoginPopup },
  props: ["currentUser", "headerTop", "isLanding"],

  methods: {
    login() {
      googleSignIn();
    },

    logout() {
      firebaseLogout();
    },

    closeMobMenuBox() {
      // remove classs
      const classes = ["header__btn-toggle--open", "header__nav--toggle"];
      classes.forEach((cls) => {
        document.querySelector(`.${cls}`)?.classList.remove(cls);
      });
    },
  },

  mounted() {
    main(this.isLanding);
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  z-index: 9991;
  top: 0;
  left: 0;
  width: 100%;
  // height: 10rem;
  background: #09305d;
  transition: all 0.3s ease;
}

// @media (max-width:991.98px) {
//   .header {
//     // height: 70px
//   }
// }

.header--landing {
  background: 0 0;
}

.header__logo {
  display: inline-block;
  position: relative;
  top: 36px;
  transition: all 0.3s ease;
}

@media (max-width: 991.98px) {
  .header__logo {
    top: 0;
  }
}

.header__logo > img {
  transition: all 0.3s ease;
  width: 238px;
}

@media (max-width: 991.98px) {
  .header__logo > img {
    width: 150px;
  }
}

.header__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .header__nav {
    right: auto;
    top: 0;
    overflow: hidden;
    position: fixed;
    width: 100%;
    left: 100%;
    background-color: #09305d;
    padding: 30px 0;
    height: 100vh;
  }
}

@media (max-width: 991.98px) {
  .header__nav--toggle {
    left: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.header__nav-item {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991.98px) {
  .header__nav-item {
    display: block;
    text-align: center;
  }
}

.header__nav-link {
  font-size: 1rem;
  font-weight: 600;
  line-height: 40px;
  display: inline-block;
  padding: 0 1rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s ease;
  border-bottom: solid 1px transparent;
}

.header__nav-link:focus,
.header__nav-link:hover {
  color: #fff;
  text-decoration: none;
  border-color: #cdecf8;
}

@media (max-width: 1199.98px) {
  .header__nav-link {
    padding: 0 10px;
  }
}

@media (max-width: 991.98px) {
  .header__nav-link {
    text-align: center;
    line-height: 60px;
  }
}

.header__btn-join {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 35px;
  margin-top: -1px;
  padding: 7px 15px;
}

@media (max-width: 991.98px) {
  .header__btn-join {
    margin-left: 0;
    margin-top: 20px;
  }
}

.header__btn-toggle {
  position: absolute;
  top: -100px;
  right: 20px;
  width: 36px;
  height: 27px;
  transition: all 0.3s ease;
  z-index: 10;
  border: none;
  background: 0 0;
  color: #fff;
}

@media (max-width: 991.98px) {
  .header__btn-toggle {
    // top: 20px
    top: 50%;
    transform: translateY(-50%);
  }
}

.header__btn-toggle:focus {
  outline: 0;
}

.header__btn-toggle-line {
  position: absolute;
  display: block;
  width: 36px;
  height: 5px;
  transition: all 0.3s ease;
  background-color: #fff;
}

.header__btn-toggle-line--1 {
  top: 0;
}

.header__btn-toggle-line--2 {
  top: 11px;
}

.header__btn-toggle-line--3 {
  bottom: 0;
}

.header__btn-toggle--open .header__btn-toggle-line--1 {
  top: 11px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header__btn-toggle--open .header__btn-toggle-line--2 {
  opacity: 0;
}

.header__btn-toggle--open .header__btn-toggle-line--3 {
  bottom: 11px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.header--top {
  top: 0;
  // height: 70px;
  background: #09305d;
}

.header--top .header__logo {
  top: -4px;
}

.header--top .header__logo > img {
  width: 150px;
}

// .header--top .header__nav {
//   top: 15px
// }

// @media (max-width:991.98px) {
//   .header--top .header__nav {
//     top: 0
//   }
// }

.header--red-banner {
  top: 50px;
}
</style>
