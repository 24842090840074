import firebase from 'firebase'
import {
  auth
} from './firebaseConfig'

function googleSignIn() {
  const google = new firebase.auth.GoogleAuthProvider()

  return auth.signInWithPopup(google)
    .then((result) => {
      console.log(result)
    })
    .catch(err => {
      console.error('Cant login:', err)
    })
}

function firebaseLogout() {
  firebase.auth().signOut()
    .then(() => {
      console.log('Successfully logged out')
    })
    .catch(error => {
      console.error(error.message)
    })
}

export {
  googleSignIn,
  firebaseLogout
}