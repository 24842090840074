<template>
  <button
    @click="login"
    type="button"
    id="login-google-button"
    style="max-width: 300px; margin: auto"
  >
    <svg
      class="active"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 48 48"
    >
      <g>
        <path
          fill="#EA4335"
          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
        ></path>
        <path
          fill="#4285F4"
          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
        ></path>
        <path
          fill="#FBBC05"
          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
        ></path>
        <path
          fill="#34A853"
          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
        ></path>
        <path fill="none" d="M0 0h48v48H0z"></path>
      </g></svg
    ><span class="icon-loading"></span> <span>Sign in with Google</span>
  </button>
</template>

<script>
import { googleSignIn } from "@/firebase/auth";

export default {
  name: "GoogleLoginButton",
  methods: {
    login() {
      googleSignIn();
      // .then(() => {
      // 	this.$router.push({name: 'UserHomePage'})
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
#login-google-button {
  display: block;
  // margin-bottom: 35px;
  border-radius: 25px;
  border: 2px solid #eee;
  color: #777278;
  font-size: 18px;
  background-color: #fff !important;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.3s ease-out;

  box-shadow: 2px 3px 9px 0px #bebebe;
}

#login-google-button:hover {
  cursor: pointer;
  border: 2px solid rgba(66, 133, 244, 0.3);
}

#login-google-button:disabled:hover {
  border: 2px solid #eee;
}

#login-google-button svg {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: none;
}

#login-google-button svg.active,
.icon-loading.active {
  display: inline-block;
}

button:focus {
  outline: none;
}
</style>
